import React, { useEffect } from "react"
import classNames from "classnames"
import { List, Map } from "immutable"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import AlertTag from "highline/components/alert_tag"
import ErrorMessage from "highline/components/pdp/error_message"
import InputWithLabel from "highline/components/input_with_label"
import LoadingButton from "highline/components/loading_button"
import LoadingCurtain from "highline/components/loading_curtain"
import OnlyXLeft from "highline/components/only_x_left"
import Options from "highline/components/pdp/options"
import PdpDrawerImages from "highline/components/pdp/pdp_drawer_images"
import Summary from "highline/components/pdp/summary"
import TermsAndPrivacy from "highline/components/terms_and_privacy/terms_and_privacy_summary"
import { generatePDPLink } from "highline/redux/helpers/category_helper"
import { getLoadingButtonText } from "highline/redux/helpers/product_detail_helper"
import styles from "highline/styles/components/pdp/product_preview.module.css"

const ProductPreview = ({
  collapsedOptions,
  coreColors,
  finalSale,
  handleNotifyMeSubmit,
  images,
  isAddingToCart,
  isGiftCard,
  isIconStatus,
  isLoading,
  isModal,
  isPreorder,
  isReady,
  onAddToCart,
  onLoad,
  onOptionChange,
  onOptionToggle,
  onOutOfStockEmailChange,
  onViewDetailsClicked,
  options,
  outOfStockFormErrors,
  outOfStockSubmitSuccess,
  outOfStockSubscriptionEmailInputValue,
  outOfStockSubscriptionErrors,
  outOfStockSubscriptionIsLoading,
  productName,
  price,
  remainingOptions,
  selectedOptions,
  showErrors,
  sku,
  slug,
  userEmail,
  variant,
  requestedOptions,
  errors,
}) => {
  useEffect(() => {
    onLoad(slug, requestedOptions)
  }, [onLoad, requestedOptions, slug])

  const color = selectedOptions.get("color")
  const filters = selectedOptions.map((value) => List([value]))
  const route = generatePDPLink(slug, color, false, false, filters)
  const variantIsOutOfStock = variant && !variant.get("inStock")
  const hasRemainingOptions = !remainingOptions.isEmpty()
  const loadingButtonText = getLoadingButtonText(
    isPreorder,
    selectedOptions,
    options,
    finalSale,
    variantIsOutOfStock
  )
  const showNotifyMeEmailInput =
    variantIsOutOfStock && !userEmail && !hasRemainingOptions && !finalSale
  const outOfStockSubscriptionEmail = userEmail || outOfStockSubscriptionEmailInputValue
  const isNotifyMeDisabled =
    finalSale || outOfStockSubscriptionIsLoading || !outOfStockSubscriptionEmail
  const isSubmitButtonDisabled =
    (variantIsOutOfStock && isNotifyMeDisabled) || (!variantIsOutOfStock && isAddingToCart)

  const handleLoadingButtonPress = () => {
    if (!variantIsOutOfStock) {
      onAddToCart()
    } else {
      handleNotifyMeSubmit(outOfStockSubscriptionEmail, options, selectedOptions, slug, productName)
    }
  }

  const displayButton = () => {
    return (
      <div className={classNames(styles.buttonWrapper, isModal ? styles.noButton : "")}>
        <ErrorMessage remainingOptions={remainingOptions} showErrors={showErrors} />

        <LoadingButton
          aria-label={`${
            variantIsOutOfStock && !isPreorder
              ? "Get notified when this product is back in stock"
              : "Add product to your shopping cart"
          }`}
          name="add_to_cart"
          onClick={handleLoadingButtonPress}
          disabled={isSubmitButtonDisabled}
          loading={isAddingToCart || outOfStockSubscriptionIsLoading}
          layout={hasRemainingOptions ? "disabled-style" : "primary"}
          data-cnstrc-btn="add_to_cart"
        >
          {loadingButtonText}
        </LoadingButton>
      </div>
    )
  }
  if (showErrors && errors) {
    return (
      <>
        <p className={styles.errorMessageDisplay}>Sorry</p>
        <p className={styles.errorMessageDisplay}>{errors}</p>
      </>
    )
  }
  return (
    <div
      className={classNames(
        "component",
        "product-preview-component",
        styles.component,
        isModal && styles.componentUnsetHeight
      )}
      data-loading={isLoading ? "true" : "false"}
      data-product-sku={sku}
      data-product-variant-sku={variant && variant.get("sku")}
      data-cnstrc-product-detail
      data-cnstrc-item-id={sku}
      data-cnstrc-item-variation-id={variant && variant.get("sku")}
      data-cnstrc-item-name={productName}
      data-cnstrc-item-price={price}
    >
      <div
        className={classNames(
          styles.summaryDetailsButtons,
          isModal ? styles.summaryDetailsIsModal : ""
        )}
      >
        <LoadingCurtain show={isLoading} />
        <div className={classNames(styles.imagesWrapper, isModal ? styles.modalImage : "")}>
          {isReady && <PdpDrawerImages images={images} isModal={isModal} />}
        </div>

        <div className={classNames(styles.contentWrapper, isModal ? styles.modalContentWidth : "")}>
          <div className={styles.summaryWrapper}>
            <Summary
              className={styles.summary}
              name={productName}
              originalPrice={price.get("fullPrice")}
              onSale={price.get("onSale")}
              price={price.get("price")}
              promoCode={price.get("promoCode")}
              promoPrice={price.get("promoPrice")}
              layout="short"
              isModal={isModal}
              isGiftCard={isGiftCard}
            />
          </div>

          <button
            onClick={() => {
              onViewDetailsClicked(route)
            }}
            className={styles.viewDetails}
          >
            View Details
          </button>

          {finalSale && (
            <div className={styles.finalSale}>
              <span className={styles.finalSaleText}>Final Sale</span> - No returns or exchanges
            </div>
          )}

          <Options
            collapsedOptions={collapsedOptions}
            coreColors={coreColors}
            isIconStatus={isIconStatus}
            onOptionChange={onOptionChange}
            onOptionToggle={onOptionToggle}
            options={options}
            productName={productName}
            remainingOptions={remainingOptions}
            selectedOptions={selectedOptions}
            showErrors={showErrors}
            slug={slug}
            showSwatches
          />

          {variant && variant.get("limitedQuantity") && (
            <OnlyXLeft className={styles.onlyXLeft} quantity={variant.get("limitedQuantity")} />
          )}

          {variantIsOutOfStock && <AlertTag className={styles.soldOut}>Sold Out</AlertTag>}

          {showNotifyMeEmailInput && (
            <div>
              <InputWithLabel
                autoCorrect="off"
                error={outOfStockFormErrors.get("email")}
                label="Email Address"
                name="email"
                onChange={(e) => onOutOfStockEmailChange(e.target.value)}
                sensitive
                spellCheck="false"
                type="email"
                value={outOfStockSubscriptionEmailInputValue}
                required
              />
              <TermsAndPrivacy
                copy={(content) => {
                  return <div>By clicking &quot;Notify Me&quot; you agree to our {content}.</div>
                }}
                layout="light"
                openInModal={false}
              />
            </div>
          )}
          {isModal && displayButton()}
        </div>
      </div>

      {!isModal && displayButton()}

      {outOfStockSubscriptionErrors && outOfStockSubscriptionErrors.has("message") && (
        <p className={styles.errorMessage}>{outOfStockSubscriptionErrors.get("message")}</p>
      )}

      {outOfStockSubmitSuccess && (
        <p className={styles.successMessage}>
          {"Successfully subscribed to notifications for this product!"}
        </p>
      )}
    </div>
  )
}

ProductPreview.propTypes = {
  className: PropTypes.string,
  collapsedOptions: ImmutablePropTypes.list,
  coreColors: ImmutablePropTypes.list,
  finalSale: PropTypes.bool,
  handleNotifyMeSubmit: PropTypes.func,
  images: ImmutablePropTypes.list,
  isAddingToCart: PropTypes.bool,
  isGiftCard: PropTypes.bool,
  isIconStatus: PropTypes.bool,
  isModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPreorder: PropTypes.bool,
  isReady: PropTypes.bool,
  metaTitle: PropTypes.string,
  onAddToCart: PropTypes.func,
  onLoad: PropTypes.func,
  onOptionChange: PropTypes.func,
  onOptionToggle: PropTypes.func,
  onOutOfStockEmailChange: PropTypes.func,
  onViewDetailsClicked: PropTypes.func,
  options: ImmutablePropTypes.list,
  outOfStockFormErrors: ImmutablePropTypes.map,
  outOfStockSubmitSuccess: PropTypes.bool,
  outOfStockSubscriptionEmailInputValue: PropTypes.string,
  outOfStockSubscriptionErrors: ImmutablePropTypes.map,
  outOfStockSubscriptionIsLoading: PropTypes.bool,
  price: ImmutablePropTypes.map,
  productName: PropTypes.string,
  remainingOptions: ImmutablePropTypes.list,
  requestedOptions: ImmutablePropTypes.map,
  selectedOptions: ImmutablePropTypes.map,
  shortDescription: PropTypes.string,
  showErrors: PropTypes.bool,
  sku: PropTypes.string,
  slug: PropTypes.string.isRequired,
  url: PropTypes.string,
  userEmail: PropTypes.string,
  variant: ImmutablePropTypes.map,
  errors: PropTypes.string,
}

ProductPreview.defaultProps = {
  collapsedOptions: List(),
  handleNotifyMeSubmit: () => {},
  isAddingToCart: false,
  isGiftCard: false,
  isIconStatus: false,
  isModal: false,
  isPreorder: false,
  isReady: true,
  onAddToCart: () => {},
  onOptionChange: () => {},
  onOptionToggle: () => {},
  onOutOfStockEmailChange: () => {},
  onViewDetailsClicked: () => {},
  options: List(),
  outOfStockFormErrors: Map(),
  outOfStockSubmitSuccess: false,
  outOfStockSubscriptionEmailInputValue: "",
  outOfStockSubscriptionErrors: Map(),
  outOfStockSubscriptionIsLoading: false,
  properties: Map(),
  selectedOptionTypes: Map(),
  shortDescription: "",
  userEmail: "",
  variant: Map(),
  variantDescriptions: List(),
  variantProperties: List(),
}

export default ProductPreview
